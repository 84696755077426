import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import img1 from './douseconcept2.jpg';
import img2 from './douseconcepts.jpg';
// import img3 from './douseplayable.jpg';
import img4 from './treelevel.jpg';
import img5 from './nightmushrooms.jpg';
import img6 from './sky_background.jpg';

export const Douse: React.FC = () => (
  <>
    <h3>Douse (2012)</h3>
    <p>
      I was Lead Artist on Douse, a poetic experience about a raindrop's journey to restore a forest
      and return home. You can read more and{' '}
      <a href="https://www.digipen.edu/showcase/student-games/douse" target="_blank" rel="noopener">
        download the game here
      </a>
      .
    </p>
    <Gallery
      photos={[
        {
          src: img1,
          alt: '',
          width: 800,
          height: 535,
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img2,
          alt: '',
          width: 830,
          height: 477,
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img4,
          alt: '',
          width: 740,
          height: 1200,
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img6,
          alt: '',
          width: 16,
          height: 9,
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img5,
          alt: '',
          width: 640,
          height: 960,
          tabIndex: 1,
          loading: 'lazy',
        },
      ]}
    />
  </>
);

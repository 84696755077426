import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import img1 from './fof.jpg';
import img2 from './fof1.jpg';
import img3 from './fof2.jpg';
// import img4 from './fof3.jpg';

export const ForestOfFinance: React.FC = () => (
  <>
    <h3>Forest of Finance (2014)</h3>
    <p>
      Forest of Finance was a 2-person, 3-day gamejam game that aimed to teach kids basic financial
      concepts. You can{' '}
      <a href="https://theskyandthesea.itch.io/forest-of-finance" target="_blank" rel="noopener">
        play it online
      </a>{' '}
      via Unity Web Player.
    </p>
    <Gallery
      photos={[
        {
          src: img1,
          alt: '',
          width: 4,
          height: 3,
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img2,
          alt: '',
          width: 4,
          height: 3,
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img3,
          alt: '',
          width: 4,
          height: 3,
          tabIndex: 1,
          loading: 'lazy',
        },
      ]}
    />
  </>
);

import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import img1 from './investigator.jpg';
import img2 from './investigator1.jpg';
import img3 from './investigator2.jpg';
import img4 from './investigator3.jpg';

export const Investigator: React.FC = () => (
  <>
    <h3>The Investigator Cases (2015)</h3>
    <p>
      The Investigator Cases are point-and-click who-dunnits about an alligator who investigates
      crime. They were made within 72 hours during Ludum Dare gamejams. You can{' '}
      <a href="https://theskyandthesea.itch.io/" target="_blank" rel="noopener">
        play them online here
      </a>
      .
    </p>
    <Gallery
      photos={[
        {
          src: img1,
          alt: '',
          width: 16,
          height: 9,
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img2,
          alt: '',
          width: 16,
          height: 9,
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img3,
          alt: '',
          width: 16,
          height: 9,
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img4,
          alt: '',
          width: 16,
          height: 9,
          tabIndex: 1,
          loading: 'lazy',
        },
      ]}
    />
  </>
);

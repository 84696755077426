import React from 'react';

import { Investigator } from './gamejams/investigator/_index';
import { ForestOfFinance } from './gamejams/forestoffinance/_index';
import { Lignum } from './student/lignum/_index';
import { Douse } from './student/douse/_index';

export const Games: React.FC = () => (
  <section>
    <h2>Games</h2>
    <p>
      While I dream of throwing up everything to start my own indie studio, I'm pretty far from that
      yet. In the meanwhile, you can{' '}
      <a href="https://theskyandthesea.itch.io/" target="_blank" rel="noopener">
        follow me on itch.io
      </a>
      .
    </p>

    <Investigator />
    <ForestOfFinance />
    <Lignum />
    <Douse />
  </section>
);

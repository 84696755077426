import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import oils1 from './oils1.jpg';
import oils2 from './oils2.jpg';
import oils3 from './oils3.jpg';
import gestures1 from './gestures4.jpg';
import gestures2 from './gestures5.jpg';
import gestures3 from './gestures6.jpg';
import gestures4 from './gestures7.jpg';
import gestures5 from './gestures1-3.jpg';
import sketches1 from './animal_gesture_1.jpg';
import sketches2 from './animal_gesture_2.jpg';
import sketches3 from './animal_anatomy.jpg';

export const TraditionalArt: React.FC = () => (
  <section>
    <h2>Traditional art</h2>
    <p>
      Once upon a time, before I realized coding paid a whole lot more money, I went to an art
      school and did neat stuff like figure drawing and oil painting. I'd still like to do more
      landscape painting someday, when I'm retired and traveling around national parks.
    </p>
    <Gallery
      photos={[
        {
          src: oils3,
          width: 8,
          height: 5,
          title: 'Forest creek',
          alt: '',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: oils2,
          width: 4,
          height: 5,
          title: 'Bike path',
          alt: '',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: oils1,
          width: 4,
          height: 3,
          title: 'State park',
          alt: '',
          tabIndex: 1,
          loading: 'lazy',
        },
      ]}
    />
    <Gallery
      photos={[
        {
          src: gestures1,
          width: 10,
          height: 13,
          title: 'Various anatomy studies',
          alt: '',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: gestures3,
          width: 10,
          height: 13,
          title: 'Gesture sketches, 2m',
          alt: '',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: gestures4,
          width: 10,
          height: 13,
          title: 'Gesture sketches, 5m',
          alt: '',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: gestures5,
          width: 10,
          height: 13,
          title: 'Gesture sketches, 2-5m',
          alt: '',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: gestures2,
          width: 10,
          height: 13,
          title: 'Gesture sketches, 5m',
          alt: '',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: sketches1,
          width: 4,
          height: 5,
          title: 'Animal sketches, 2-5m',
          alt: '',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: sketches2,
          width: 3,
          height: 4,
          title: 'Bird sketches, 2-5m',
          alt: '',
          tabIndex: 1,
          loading: 'lazy',
        },
        // {
        //   src: sketches3,
        //   width: 9,
        //   height: 4,
        //   title: 'Bear skeleton study, 30m+',
        //   alt: '',
        //   tabIndex: 1,
        //   loading: 'lazy',
        // },
      ]}
    />
  </section>
);

import React from 'react';

import { Head } from 'src/components/head/head';
import { Layout } from 'src/components/layout/layout';
import { Logo } from './_logo';
import thumbnail from './thumbnail.jpg';

import { Games } from './games/_index';
import { TraditionalArt } from './traditional/_index';
import { Photography } from './_photography';

export const Art: React.FC = () => (
  <>
    <Head title="Art" path="/art" ogImage={thumbnail} />
    <Layout logo={<Logo />}>
      <h1 className="page-intro">The Pen & the Pixel</h1>
      <p>
        Art is hard and I’m not sure why I do it. Is it for myself? Is it in some futile effort to
        reach out and in some way impact or connect with a few souls out of the billions on this
        teeming planet? Hell if I know.
      </p>

      <Games />
      {/* <h2>Illustration</h2> TODO - etsy link?*/}
      {/* <h2>Animation</h2> TODO - codepen link? */}
      <TraditionalArt />
      <Photography />
    </Layout>
  </>
);

export default Art;

import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

export const Photography: React.FC = () => {
  const unsplashUrl = 'https://unsplash.com/@theskyandthesea';

  return (
    <section>
      <h2>Photography</h2>
      <p>
        This feels like a bit of a gimme section, as my travel/plants pages are all photography. But
        if you'd like to follow me or use some of my photos totally free, you can do so on{' '}
        <a href={unsplashUrl} target="_blank" rel="noopener">
          Unsplash
        </a>
        .
      </p>
      <Gallery
        photos={[
          {
            src: 'https://images.unsplash.com/photo-1594353162678-e154ec5353bf?w=600&q=60',
            externalUrl: unsplashUrl,
            width: 3,
            height: 2,
            alt: '',
            tabIndex: 1,
            loading: 'lazy',
          },
          {
            src: 'https://images.unsplash.com/photo-1531025115105-a0585c38d66f?&w=600&q=60',
            externalUrl: unsplashUrl,
            width: 3,
            height: 2,
            alt: '',
            tabIndex: 1,
            loading: 'lazy',
          },
          {
            src: 'https://images.unsplash.com/photo-1477747219299-60f95c811fef?w=600&q=60',
            externalUrl: unsplashUrl,
            width: 3,
            height: 2,
            alt: '',
            tabIndex: 1,
            loading: 'lazy',
          },
        ]}
      />
    </section>
  );
};

import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import img1 from './1.jpg';
import img2 from './2.jpg';
import img3 from './3.jpg';
import img4 from './5.jpg';
// import img5 from './11.jpg';

export const Lignum: React.FC = () => (
  <>
    <h3>Lignum (2013)</h3>
    <p>
      I was Lead Artist on Lignum, a point and click adventure game set in a treetop village. You
      can view a video trailer and{' '}
      <a href="https://arcade.digipen.edu/games/lignum" target="_blank" rel="noopener">
        download the game here
      </a>
      .
    </p>
    <Gallery
      photos={[
        {
          src: img1,
          alt: '',
          width: 16,
          height: 9,
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img2,
          alt: '',
          width: 16,
          height: 9,
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img3,
          alt: '',
          width: 16,
          height: 9,
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img4,
          alt: '',
          width: 16,
          height: 9,
          tabIndex: 1,
          loading: 'lazy',
        },
      ]}
    />
  </>
);
